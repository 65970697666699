import React from "react"
import Helmet from 'react-helmet'


export default function Privacy () {
	return (
		<>
		<Helmet>
			<title>Privacy Policy - Craft Boxing</title>
		</Helmet>
		<iframe title="privacy policy" src="https://app.termly.io/document/privacy-policy/a5785d62-1df8-47ee-935a-6e3aacb3e1b4"
		width="100%"
		height="2000px"
		frameBorder="0"  />
		</>
	)
}